<template>
  <div class="login-page">
    <Logo :width="160" :height="48" class="login-page__logo" />
    <Title class="mb-24">Sign in</Title>
    <div v-if="isLoginError" class="login-page__error">
      <Icon name="warning" color="danger" />
      User is not found or the password is incorrect
    </div>
    <Form v-slot="{ invalid }" class="mb-40">
      <FormItem rules="required|email" v-slot="{ isError }" class="mb-24">
        <Input
          v-model="form.email"
          placeholder="example@domain.com"
          :is-error="isError"
          :is-floating="false"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }" class="mb-24">
        <Input
          v-model="form.password"
          is-password
          placeholder="Password"
          postfix="password"
          :is-error="isError"
          :is-floating="false"
        />
      </FormItem>
      <FormItem class="login-page__remember">
        <Switcher v-model="form.rememberMe" label="Remember me" />
        <router-link
          :to="{ name: 'ForgotPassword' }"
          class="login-page__remember__forgot"
        >
          Forgot password?
        </router-link>
      </FormItem>
      <div class="login-page__actions">
        <Button
          is-block
          :is-disabled="invalid || isGoogleLoading"
          :is-loading="isCredentialsLoading"
          @click="loginCredentials"
        >
          Sign in
        </Button>
        <div class="login-page__actions__divider">Or</div>
        <Button
          variant="secondary"
          isRaised
          is-block
          :is-disabled="isCredentialsLoading"
          :is-loading="isGoogleLoading"
          @click="loginGoogle"
        >
          <Icon name="google" />
          Login with Google
        </Button>
      </div>
      <div class="login-page__tip">
        If you are not associated with any venue,<br />
        please, contact our sales team.<br />
        <a :href="`mailto:${salesTeamEmail}`">{{ salesTeamEmail }}</a>
      </div>
    </Form>
    <!--    <p class="login-page__signup">-->
    <!--      Don’t have an account?-->
    <!--      <router-link :to="{ name: 'SignUp' }" class="login-page__signup__link">-->
    <!--        Sign up-->
    <!--      </router-link>-->
    <!--    </p>-->
  </div>
</template>

<script>
import Logo from "@/components/common/Logo";
import {
  loginWithCredentials,
  loginWithGoogle,
} from "@/helpers/firebase-helpers";
import alert from "@/plugins/alert";

export default {
  name: "LoginPage",
  components: { Logo },
  data() {
    return {
      isGoogleLoading: false,
      isCredentialsLoading: false,
      form: {
        email: "",
        password: "",
        rememberMe: true,
      },
      isLoginError: false,
      salesTeamEmail: process.env.VUE_APP_SALES_TEAM_EMAIL,
    };
  },
  methods: {
    async loginGoogle() {
      try {
        this.isGoogleLoading = true;
        await loginWithGoogle();
      } catch (ex) {
        if (ex.code === "auth/user-disabled") {
          await this.showAccountDisabledAlert();
        }
      } finally {
        this.isGoogleLoading = false;
      }
    },
    async loginCredentials() {
      try {
        this.isCredentialsLoading = true;
        await loginWithCredentials(this.form);
        this.isLoginError = false;
      } catch (ex) {
        this.isLoginError = true;
        if (ex.code === "auth/user-disabled") {
          await this.showAccountDisabledAlert();
        }
      } finally {
        this.isCredentialsLoading = false;
      }
    },
    showAccountDisabledAlert() {
      return alert.open({
        message: `Account is disabled.
          Most likely this happened because you are not associated with any venue.
          Please, contact our sales team: ${this.salesTeamEmail}`,
        delay: 15000,
        variant: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  &__logo {
    margin: 0 auto 40px;

    @media (min-width: $media-laptop) {
      margin-bottom: 88px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $danger;
    margin-bottom: 12px;
  }

  &__remember {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 48px;

    &__forgot {
      font-size: 12px;
      line-height: 23px;
      color: $primary;
      text-decoration: none;

      &:hover {
        color: $primary-hover;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 36px;

    &__divider {
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;
      display: flex;
      align-items: center;
      text-align: center;

      &::after,
      &::before {
        content: "";
        border: 0.5px solid $secondary-300;
        flex: 1;
      }

      &:not(:empty)::before {
        margin-right: 16px;
      }

      &:not(:empty)::after {
        margin-left: 16px;
      }
    }
  }

  &__tip {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: $secondary-500;

    a {
      color: $primary;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        color: $primary-hover;
      }
    }
  }
}
</style>
